/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Use our API explorer to view a detailed breakdown of our API and to discover how you can integrate with it to do the following:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Board merchants"), " - Add merchants to your organization and set up their accounts."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Manage funding accounts"), " - Move funds to your merchants and other organizations."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Run transactions"), " - Take payments from customers using a range of payment methods."), "\n"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Our API supports the following features:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/idempotency"
  }, "Idempotency")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/metadata"
  }, "Metadata")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/pagination"
  }, "Pagination")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/versioning"
  }, "Versioning")), "\n"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "We also provide easy-to-use ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/guides/integrate"
  }, "guides"), " that contain all the information you need to integrate with the key functions of our API."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
